
import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Observer, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { TreeNode } from 'primeng/api';
import { AppConfig } from '.';
import { SaiUtilsService } from './sai-utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {

  private url: string = null;
  private bgUrl: string = null;
  private customer: string = null;
  base64Image: any;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private store: Store,
    private http: HttpClient,
    private appConfig: AppConfig,
    private utils: SaiUtilsService
  ) {
    this.url = appConfig.baseConfig['configApi'];
    this.bgUrl = appConfig.baseConfig['backgroundApi'];
    this.customer = appConfig.baseConfig['customer'];

  }

  getAssetTree(root: string) {
    const url = `https://${this.url}getassettree/${root}`;
    return this.http.get<any>(url).toPromise().then(res => res.Item.data as TreeNode[],
      (err) => {
        console.log('Error while loading asset tree: ', err);
        return [];
      });
  }

  getAssetSensors(asset) {
    const url = `https://${this.url}getassetsensors2/${asset}`;

    return this.http.get<any>(url).toPromise().then(res => {
      return res?.Item?.sensors as TreeNode[];
    }, (err) => {
      console.log('Error while loading sensors for asset: ', err);
      return [];
    });
  }

  public getMeasurementsForMetric(device: string, sensor: string, metric: string, timerange: string) {
    const url = `https://${this.url}getmeasurementsformetrics?deviceId=${device}&sensor=${sensor}&metric=${metric}&timerange=${timerange}`;
    return this.http.get<any>(url, this.httpOptions).toPromise().then(res => res,
      (err) => {
        console.log('Error occurred while loading metrics: ', err);
        return [];
      });
  }

  getScreenConfigFor = (asset: string) => {
    return new Promise((resolve, reject) => {
      var screenconfig: any = null;
      this.http
        .get('https://' + this.url + 'getscadaconfiguration/' + asset, this.httpOptions)
        .toPromise()
        .then((response: any) => {
          if (this.utils.getNested(response.body.Item, 'asset')) {
            if (response.body.Item.asset === asset) {
              screenconfig = response.body.Item
            }
          }
          resolve(screenconfig);
        });
    })
  }

  getLatestValues = (device: string) => {
    return new Promise((resolve, reject) => {
      var res: any;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      const req = `https:/${this.url}getmeasurementssince?deviceId=${device}&timerange=5m`;
      res = this.http
        .get(
          req,
          httpOptions
        )
        .toPromise()
        .then((response) => {
          resolve(response);

        });
      resolve(res);
    })
  }

  getAlarmStatusses = (device: string, dynamics: any) => {

    return new Promise((resolve, reject) => {
      var res: any = null;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      this.http
        .get(`https://bllzzi5soi.execute-api.eu-west-1.amazonaws.com/v1/getalarmstatusses?deviceId=sec-0028-0042`)
        .toPromise()
        .then((response: any) => {
          resolve(reshapeAlarms(response, dynamics))
        });
    })
  }


  getHistory = (device: string, timerange: string) => {
    return new Promise((resolve, reject) => {
      var res: any;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      const rq2 = `https://${this.url}getmeasurementssince?deviceId=${device}&timerange=${timerange}`;
      res = this.http
        .get(
          rq2,
          httpOptions
        )
        .toPromise()
        .then((response) => {
          const tt: any[] = <Array<any>>response;
          resolve(response);

        });
      resolve(res);
    })

  }
}


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function reshapeHist(histData: any[]) {
  var sensors = histData
    .map((el) => {
      return el.s;
    })
    .filter(onlyUnique);

  var result: {} = {};

  for (let sensor of sensors) {
    const sensorHist = histData.filter((el) => {
      return el.s == sensor;
    });
    var timestamps: {} = {};
    sensorHist[0].v.forEach((element) => {
      timestamps[element.ts] = {
        ts: element.ts,
      };
    });

    for (let i = 0; i < sensorHist.length; i++) {
      for (let j = 0; j < sensorHist[i]["v"].length; j++) {
        var tsKey = sensorHist[i]["v"][j]["ts"].toString();
        timestamps[tsKey][sensorHist[i]["m"]] = sensorHist[i]["v"][j]["v"];
      }
    }
    result[sensor] = [];
    Object.keys(timestamps).forEach(ts => {
      result[sensor].push(timestamps[ts]);
    })
  }
  return (result);
}

function reshapeAlarms(input: any, dynamics: any) {
  let res = {};
  input.Rows.forEach((row, index) => {
    let dynamic='meas.'+row.Data[0].ScalarValue.split('.')[0]+
                 "." +  row.Data[0].ScalarValue.split('.')[2];
    if (dynamics[dynamic]) {
      res['a' + index.toString()] = {
        s: row.Data[1].ScalarValue,
        ctx: {
          oid: row.Data[2].ScalarValue,
          id: row.Data[3].ScalarValue,
          reason: row.Data[4].ScalarValue,
          vals: [
            {
              p: row.Data[0].ScalarValue,
              v: "good"
            }
          ]
        }
      }
    }
  });
  return {
    ts: Date.now(),
    res: res
  }
};