import { LiveDataState } from './../../state/live-data/live-data.state';
import { LiveDataService } from './live-data.service';
import {
  SetConnected,
} from './../../state/live-data/live-data.actions';
import { AssetTreeState } from './../../state/asset-tree/asset-tree.state';
import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';
// import { connect, MqttClient } from 'mqtt';
import { Observable, Subject, Subscription } from 'rxjs';
// import { NewChartData } from '../../app/state/chart-data/chart-data.actions';
import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { AppConfig } from '.';
import { bool } from 'aws-sdk/clients/signer';

const ref = 'awsiot';

@Injectable({
  providedIn: 'root',
})
export class AwsIotService {

  @Select(LiveDataState.getConnectState) connectedToMqtt$: Observable<boolean>;
  private connectedToMqtt = false;

  private measurementSource: Subject<string> = new Subject<string>();
  measurements$: Observable<string> = this.measurementSource.asObservable();

  private backgroundSource: Subject<string> = new Subject<string>();
  background$: Observable<string> = this.backgroundSource.asObservable();

  private commandsSub: Subscription;
  private rawTopicSubscriptions: any = {};
  private rawTopicKeys = [];
  private reverseRawTopics: any = null;

  private lifebeatReceived = new Date().valueOf();
  private lifeBeatTimer = null;

  public historyFromMqtt: bool;

  constructor(
    private store: Store,
    private liveDataService: LiveDataService,
    private appConfig: AppConfig
  ) {
    if (this.appConfig.baseConfig['generalConfig']['remote']) {
      this.iotConnect();
    };
  }

  iotConnect() {
    return new Promise<void>((resolve, reject) => {
      this.historyFromMqtt = this.appConfig.baseConfig['historyFromMqtt'];
      console.log('connecting to AWS');
      Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: 'eu-west-1',
        aws_pubsub_endpoint: `wss://${this.appConfig.baseConfig['liveDataUrl']}/mqtt`,
      }));
      this.liveDataService.commands.subscribe((cmd) => {
        const topic = 'set/' + cmd.sourceElem + '/sp';

        this.awsPublish(cmd.sourceItem, cmd.sourceElem, cmd.command.toString());
      });
      this.connectedToMqtt$.subscribe((connectedToMqtt: boolean) => {
        if (this.connectedToMqtt) {
          if (!connectedToMqtt) {
          }
        }
        this.connectedToMqtt = connectedToMqtt;
      });
    });
  }

  subscribeToRawTopics(rawTopics: any) {
    //console.log('subscribing to AWS rawTopics');
    this[ref].wildcardCounter = 0;
    this[ref].rawTopicKeys.forEach(topic => {
      this[ref].rawTopicSubscriptions[topic].unsubscribe();
    });
    this[ref].rawTopicKeys = Object.keys(rawTopics);
    this[ref].reverseRawTopics = {};
    this[ref].rawTopicKeys.forEach(topic => {
      //console.log(rawTopics[topic]);
      this[ref].reverseRawTopics[rawTopics[topic]] = topic;
      this[ref].rawTopicSubscriptions[topic] = new Subscription;
      this[ref].rawTopicSubscriptions[topic] = Amplify.PubSub.subscribe(rawTopics[topic]).subscribe({
        next: data => {
          const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
          this[ref].liveDataService.processMqttMessages(topic,
            data.value,
            this[ref].reverseRawTopics[topic]);

        },
        error: error => console.error(error),
        close: () => console.log('Done'),
      })
    });
  }

  unsubscribeFromRawTopics() {
    this[ref].rawTopicKeys.forEach(topicKey => {
      this[ref].rawTopicSubscriptions[topicKey].unsubscribe()
    })
  }


  async awsPublish(device: string, rawtopic, message: string) {
    const prefix = device + '/';
    const topic = 'sec-0028-0042/set/rec_fan';
    console.log('publishing topic', topic, message);
    await PubSub.publish(topic, message);
  }

}
